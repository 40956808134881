/*
 * iiimagine-frontend - index.css
 * index.css
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-01-24
 */

@tailwind base;
@tailwind components;

@layer base {
  body {
    @apply bg-black;
  }
  @font-face {
    font-family: "Curtisalto";
    src: url("assets/fonts/curtisalto.otf") format("opentype"),
      url("assets/fonts/curtisalto.ttf") format("truetype");
  }

  @font-face {
    font-family: "Signika";
    src: url("assets/fonts/signika-light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Signika";
    src: url("assets/fonts/signika-regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Signika";
    src: url("assets/fonts/signika-medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "Signika";
    src: url("assets/fonts/signika-semibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "Signika";
    src: url("assets/fonts/signika-bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "Space Grotesk";
    src: url("assets/fonts/spacegrotesk-light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Space Grotesk";
    src: url("assets/fonts/spacegrotesk-regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Space Grotesk";
    src: url("assets/fonts/spacegrotesk-medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "Space Grotesk";
    src: url("assets/fonts/spacegrotesk-semibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "Space Grotesk";
    src: url("assets/fonts/spacegrotesk-bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
  }
}

@tailwind utilities;

.previousShowPreview {
  margin-top: -40px;
}
.previousShowPreview:after {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: radial-gradient(
      circle,
      rgba(0, 0, 0, 0) 65%,
      rgba(0, 0, 0, 1) 100%
    ),
    linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 15%),
    linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 15%),
    linear-gradient(270deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 15%),
    linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 15%);
}

.react-player__preview {
  opacity: 0.6;
}

.gallery-slider .slick-prev {
  left: -18px;
}

.gallery-slider .slick-next {
  right: -18px;
}
.slick-slider {
  pointer-events: auto;
}
